
import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import { ThemeSelectorCard } from '@/interfaces/theme-selector'

@Component({
  name: 'WhereWeHelp',
  components: { container, heading },
})
export default class WhereWeHelp extends Vue {
  cid: string = ''

  get selectedCard(): ThemeSelectorCard {
    return this.cards.find(c => c.id === this.selectedCardId)!
  }

  get selectedCardId() {
    return this.cid
  }

  set selectedCardId(v) {
    this.cid = v
    window.scrollTo(0, 0)
  }

  get cards(): ThemeSelectorCard[] {
    return [
      {
        id: 'manafwa',
        title: this.$i18n.t('manafwa.title') as string,
        shortDesc: this.$i18n.t('manafwa.shortDesc') as string,
        cover: 'manafwa1'
      },
      {
        id: 'karamoja',
        title: this.$i18n.t('karamoja.title') as string,
        shortDesc: this.$i18n.t('karamoja.shortDesc') as string,
        cover: 'karamoja2',
      },
      {
        id: 'nairobi',
        title: this.$i18n.t('nairobi.title') as string,
        shortDesc: this.$i18n.t('nairobi.shortDesc') as string,
        cover: 'nairobi1'
      },
      {
        id: 'mombasa',
        title: this.$i18n.t('mombasa.title') as string,
        shortDesc: this.$i18n.t('mombasa.shortDesc') as string,
        cover: 'mombasa1'
      },
    ]
  }
}

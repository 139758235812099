
import { Component, Vue } from 'vue-property-decorator'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

import card from '@/components/moyo/card.vue'
import modal from '@/components/moyo/modal.vue'
import { Bio } from '@/interfaces/bio'

@Component({
  name: 'Team',
  components: { card, Swiper, SwiperSlide, modal },
})
export default class Team extends Vue {
  swiperOptions = {
    autoplay: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slidesPerGroup: 1,
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerGroup: 2,
        slidesPerView: 2,
      },
      1280: {
        slidesPerGroup: 2,
        slidesPerView: 3,
      },
    },
  }

  showModal(id: string) {
    const swiperRef = (this.$refs.swiperRef as any).$swiper
    const isMobile = window.innerWidth < 768
    swiperRef.autoplay.stop()
    this.$modal.show(
      modal,
      {
        bio: [...this.teamMembers, ...this.volunteers].find((m) => m.id === id),
        id,
      },
      {
        width: isMobile ? '90%' : '700px',
        height: 'auto',
      }
    )
  }

  getLongBio(name: string): string[] {
    return Object.values(this.$i18n.t(`${name}.longBio`) as any)
  }

  get teamMembers(): Bio[] {
    return [
      {
        id: 'kzsofi',
        name: 'Könczöl Zsófi',
        image: 'team/Zsofi.jpg',
        shortBio: this.$i18n.t('kzsofi.shortBio') as string,
        longBio: this.getLongBio('kzsofi'),
        objectPosition: 'auto',
      },
      {
        id: 'szbalazs',
        name: 'Szilágyi Balázs',
        image: 'team/Balazs.jpg',
        shortBio: this.$i18n.t('szbalazs.shortBio') as string,
        longBio: this.getLongBio('szbalazs'),
        objectPosition: '50% 70%',
      },
      {
        id: 'perzsa',
        name: 'Pocsai Erzsa',
        image: 'team/Erzsa.jpg',
        shortBio: this.$i18n.t('perzsa.shortBio') as string,
        longBio: this.getLongBio('perzsa'),
        objectPosition: '50% 20%',
      },
      {
        id: 'ffbalazs',
        name: 'dr. Farkas Ferenc Balázs',
        image: 'team/FFBalazs.jpg',
        shortBio: this.$i18n.t('ffbalazs.shortBio') as string,
        longBio: this.getLongBio('ffbalazs'),
        objectPosition: 'auto',
      },
      {
        id: 'vmarci',
        name: 'dr. Végh Marcell',
        image: 'team/Marci.jpg',
        shortBio: this.$i18n.t('vmarci.shortBio') as string,
        longBio: this.getLongBio('vmarci'),
        objectPosition: '50% 45%',
      },
      {
        id: 'kpeter',
        name: 'Király Péter',
        image: 'team/Peti.jpg',
        shortBio: this.$i18n.t('kpeter.shortBio') as string,
        longBio: this.getLongBio('kpeter'),
        objectPosition: '50% 25%',
      },
      {
        id: 'kanna',
        name: 'Dr. Kiss Anna',
        image: 'team/Anna.jpg',
        shortBio: this.$i18n.t('kanna.shortBio') as string,
        longBio: this.getLongBio('kanna'),
        objectPosition: '50% 40%',
      },
      {
        id: 'tklaudia',
        name: 'Tóth Klaudia',
        image: 'team/Klaudia.jpg',
        shortBio: this.$i18n.t('tklaudia.shortBio') as string,
        longBio: this.getLongBio('tklaudia'),
        objectPosition: '50% 30%',
      },
      {
        id: 'tliliana',
        name: 'Tóth Liliána',
        image: 'team/Liliana.jpg',
        shortBio: this.$i18n.t('tliliana.shortBio') as string,
        longBio: this.getLongBio('tliliana'),
        objectPosition: 'auto',
      },
      {
        id: 'mpanna',
        name: 'Makó Panna',
        image: 'team/Panna.jpg',
        imageSmall: 'team/Panna_s.jpg',
        shortBio: this.$i18n.t('mpanna.shortBio') as string,
        longBio: this.getLongBio('mpanna'),
        objectPosition: 'center bottom',
      },
      {
        id: 'njuli',
        name: 'Németh Júlia',
        image: 'team/Juli.jpg',
        shortBio: this.$i18n.t('njuli.shortBio') as string,
        longBio: this.getLongBio('njuli'),
        objectPosition: '50% 40%',
      },
    ]
  }

  get volunteers(): Bio[] {
    return [
      {
        id: 'ibarbi',
        name: 'Illyés Györfi Barbi',
        image: 'team/illyes_barbi.jpg',
        shortBio: '',
        longBio: this.getLongBio('ibarbi'),
        objectPosition: 'center 25%',
      },
      {
        id: 'kdori',
        name: 'Kozári Dóra Tímea',
        image: 'team/kozari_dori.jpg',
        shortBio: '',
        longBio: this.getLongBio('kdori'),
        objectPosition: 'center 14%',
      },
      {
        id: 'btunde',
        name: 'Balogh Tünde',
        image: 'team/balogh_tunde.jpg',
        shortBio: '',
        longBio: this.getLongBio('btunde'),
        objectPosition: 'center 30%',
      },
      {
        id: 'sdani',
        name: 'Steigler Dániel',
        image: 'team/steigler_dani.jpg',
        shortBio: '',
        longBio: this.getLongBio('sdani'),
        objectPosition: 'center 25%',
      },
      {
        id: 'magi',
        name: 'Dr. Musicz Ágnes',
        image: 'team/musicz_agi.jpg',
        shortBio: '',
        longBio: this.getLongBio('magi'),
        objectPosition: 'center center',
      },
      {
        id: 'zcsenge',
        name: 'Záhoczky Csenge',
        image: 'team/zahoczky_csenge.jpg',
        shortBio: '',
        longBio: this.getLongBio('zcsenge'),
        objectPosition: 'center 30%',
      },
      {
        id: 'vjuli',
        name: 'Vető Julianna',
        image: 'team/veto_juli.jpg',
        shortBio: '',
        longBio: this.getLongBio('vjuli'),
        objectPosition: 'center 30%',
      },
      {
        id: 'dklaudia',
        name: 'Dankai Klaudia',
        image: 'team/dankai_klaudia.jpg',
        shortBio: '',
        longBio: this.getLongBio('dklaudia'),
        objectPosition: 'center top',
      },
      {
        id: 'pbadel',
        name: 'Pribelszki-Balatincz Adél',
        image: 'team/pbadel.jpg',
        shortBio: '',
        longBio: this.getLongBio('pbadel'),
        objectPosition: 'center 55%',
      },
      {
        id: 'knori',
        name: 'Kárász Nóra',
        image: 'team/karasz_nori.jpg',
        shortBio: '',
        longBio: this.getLongBio('knori'),
        objectPosition: 'center center',
      },
      {
        id: 'bdora',
        name: 'Barabás Dóra',
        image: 'team/barabas_dora.jpg',
        shortBio: '',
        longBio: this.getLongBio('bdora'),
        objectPosition: 'center center',
      },
      {
        id: 'ttlilla',
        name: 'Tóth-Tatai Lilla',
        image: 'team/ttlilla.jpg',
        shortBio: '',
        longBio: this.getLongBio('ttlilla'),
        objectPosition: 'center 30%',
      },
      {
        id: 'zzoe',
        name: 'Zahorák Zoe',
        image: 'team/zzoe.jpg',
        shortBio: '',
        longBio: this.getLongBio('zzoe'),
        objectPosition: 'center 25%',
      },
      {
        id: 'pagyongyi',
        name: 'dr. Pap Anna Gyöngyi',
        image: 'team/pagyongyi.jpg',
        shortBio: '',
        longBio: this.getLongBio('pagyongyi'),
        objectPosition: 'center 45%',
      },
      {
        id: 'cpanni',
        name: 'Czikkely Panni',
        image: 'team/cpanni.jpg',
        shortBio: '',
        longBio: this.getLongBio('cpanni'),
        objectPosition: 'center 25%',
      },
      {
        id: 'ldorina',
        name: 'László Dorina',
        image: 'team/ldorina.jpg',
        shortBio: '',
        longBio: this.getLongBio('ldorina'),
        objectPosition: 'center center',
      },
    ]
  }
}

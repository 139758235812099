
import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import Gallery from '@/components/Gallery.vue'
import { Bio } from '@/interfaces/bio'

@Component({
  name: 'GraduateAGirl',
  components: { container, heading, Gallery },
})
export default class GraduateAGirl extends Vue {
  girls: Bio[] = [
    {
      id: 'hmoureen',
      name: 'Hasacha Moureen',
      image: 'graduate-a-girl/girls/Hasacha-Moureen.jpeg',
      shortBio: [
        'Kor: 12',
        'Iskola: Általános iskola 3. osztály',
        'Testvérek száma: 5',
      ],
      longBio: [
        'A családban lévő gyerekek anyjukkal élnek, miután apjuk egy balesetben életét vesztette. Anyjuk alkalmi munkákat tud vállalni, amely általában bérföldművelés. Ebből némi bevételt szerez a családnak, de sokat nélkülöznek, nem minden gyerek tud folyamatosan iskolába járni.',
      ],
      objectPosition: '50% 10%',
    },
    {
      id: 'anicholas',
      name: 'Ariho Nicholas',
      image: 'graduate-a-girl/girls/Ariho-Nicholas.jpeg',
      shortBio: [
        'Kor: 13',
        'Iskola: Általános iskola 7. osztály',
        'Testvérek száma: 1',
      ],
      longBio: [
        'Eredetileg Uganda nyugati részéről származik az édesanya és két gyermeke, viszont a mindennapi bántalmazások miatt elmenekültek onnan. Úgy kerültek Manafwába, hogy férje elől – aki rendszeresen erőszakolta az anyát s verte gyermekeit, a lehető legtávolabb akart kerülni, ahol nem talál rá a családra.',
        'Az édesanya jelenleg az egyesület fenntartásában lévő iskola szakácsa, így szerény jövedelme mellett, egyedülálló anyaként próbálja eltartani magát és két gyerekét. Kislánya Miracle bent van gyermektámogatási programunkban, és már jelképes örökbefogadóra lelt.',
        'Ariho nagyon életvidám fiú, bár kicsit félénk, vélhetően a gyerekkori megpróbáltatások miatt. Szeretne tovább tanulni és középiskolába járni. Jelenleg ő az egyetlen fiú a Graduate a Girl programban.',
      ],
      objectPosition: '50% 20%',
    },
    {
      id: 'njudith',
      name: 'Nasike Judith',
      image: 'graduate-a-girl/girls/Nasike-Judith.jpeg',
      shortBio: [
        'Kor: 11',
        'Iskola: Általános iskola 4. osztály',
        'Testvérek száma: 4',
      ],
      longBio: [
        '11 éves, általános iskola negyedik osztályos. 4 testvére van, 3 lány és egy 7 éves kisfiú Sam, aki súlyos testi és szellemi fogyatékkal él.',
        'Szüleik gyakran veszekszenek a gyerekek előtt és van rá példa, hogy őket is megütik. Az apa nem akarja fizetni a lányok tandíját, azt mondja, ha az anya szeretné, hogy iskolába járjanak, teremtse elő számukra a szükséges összeget. Ez sajnos kivitelezhetetlen. Általában naponta egyszer tudnak csak enni, este, hogy ne feküdjenek le éhesen.',
        'Judith sokszor az anyukája helyett is dolgozik és helyt áll. Van, hogy iskola közben neki kell hazamennie a beteg testvérét felügyelni, ehhez ki kell hagynia órákat az iskolából. Az iskolája 2 órányira van otthonról, már 6 előtt elindul otthonról, hogy beérjen a kezdésre.',
        'Judith nagyon empatikus, előszeretettel segít másokon.',
      ],
      objectPosition: '50% 10%',
    },
  ]
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content"},[_c('heading',[(!_vm.selectedArticle)?_c('div',{attrs:{"slot":"main"},slot:"main"},[_vm._v("Blog")]):_vm._e(),(_vm.selectedArticle)?_c('div',{attrs:{"slot":"main-small"},slot:"main-small"},[_vm._v(" "+_vm._s(_vm.selectedArticle.title)+" ")]):_vm._e(),(_vm.selectedArticle)?_c('div',{staticClass:"article-header",attrs:{"slot":"description"},slot:"description"},[_c('div',{staticClass:"meta"},[_vm._v(" "+_vm._s(new Date(_vm.selectedArticle.date).toLocaleDateString(_vm.$i18n.locale, { dateStyle: 'long', }))+" "),(_vm.selectedArticle.author)?_c('span',[_vm._v(" | ")]):_vm._e(),(_vm.selectedArticle.author)?_c('span',{staticClass:"author"},[_vm._v(_vm._s(_vm.selectedArticle.author))]):_vm._e()]),_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":'/blog'}},[_vm._v("← Vissza")])],1)]):_vm._e()]),(_vm.selectedArticle)?_c('container',[_c('div',{staticClass:"article-view"},[(_vm.selectedArticle.innerCover !== null)?_c('div',{staticClass:"pic"},[_c('img',{style:((_vm.selectedArticle.innerCover || _vm.selectedArticle.cover).style),attrs:{"src":require(`@/assets/images/blog/${
              (_vm.selectedArticle.innerCover || _vm.selectedArticle.cover).name
            }`),"alt":""}}),((_vm.selectedArticle.innerCover || _vm.selectedArticle.cover).caption)?_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(
            (_vm.selectedArticle.innerCover || _vm.selectedArticle.cover).caption
          )}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"blog-text"},[_vm._l((_vm.selectedArticle.blocks),function(block,i){return [(block.type === 'text')?_c('p',{key:i,class:block.classes,style:(block.style),domProps:{"innerHTML":_vm._s(block.content)}}):_vm._e(),(block.type === 'img')?_c('div',{key:i,staticClass:"blog-pics",class:block.classes},_vm._l((block.content),function(img,j){return _c('div',{key:j,staticClass:"pic",class:img.class,style:(img.wrapperStyle)},[_c('img',{style:(img.style),attrs:{"src":require(`@/assets/images/blog/${img.name}`),"alt":""}}),(img.caption)?_c('span',{staticClass:"caption",domProps:{"innerHTML":_vm._s(img.caption)}}):_vm._e()])}),0):_vm._e(),(block.type === 'list' && block.listType === 'ul')?_c('ul',{key:i,class:block.classes},_vm._l((block.content),function(l,index){return _c('li',{key:index},[_vm._v(_vm._s(l))])}),0):_vm._e(),(block.type === 'list' && block.listType === 'ol')?_c('ol',{key:i,class:block.classes},_vm._l((block.content),function(l,index){return _c('li',{key:index},[_vm._v(_vm._s(l))])}),0):_vm._e()]})],2)])]):_vm._e(),(!_vm.selectedArticle)?_c('container',{staticClass:"articles-list"},[_vm._l((_vm.articlesInPage),function(article,index){return _c('div',{key:index,staticClass:"article-extract"},[_c('div',{staticClass:"pic"},[_c('router-link',{attrs:{"to":`/blog/${article.id}`}},[_c('img',{style:(article.cover.style),attrs:{"src":require(`@/assets/images/blog/${article.cover.name}`),"alt":""}})])],1),_c('div',{staticClass:"text"},[_c('div',{staticClass:"title sub-heading"},[_vm._v(_vm._s(article.title))]),_c('div',{staticClass:"meta"},[_vm._v(" "+_vm._s(new Date(article.date).toLocaleDateString(_vm.$i18n.locale, { dateStyle: 'long', }))),(article.author)?_c('span',[_vm._v(" | ")]):_vm._e(),(article.author)?_c('span',{staticClass:"author"},[_vm._v(_vm._s(article.author))]):_vm._e()]),_c('div',{staticClass:"blog-text",domProps:{"innerHTML":_vm._s(
            article.snippet ||
            article.blocks
              .filter((b) => b.type === 'text')
              .map((b) => b.content)
              .join(' ')
              .slice(0, 500)
          )}})]),_c('div',{staticClass:"to-article"},[_c('router-link',{attrs:{"to":`/blog/${article.id}`}},[_vm._v("Tovább →")])],1)])}),_c('b-pagination',{attrs:{"total-rows":_vm.articles.length,"per-page":_vm.articlesPerPage,"align":'center',"limit":10},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Prop, Vue } from 'vue-property-decorator'
import card from '@/components/moyo/card.vue'
import modal from '@/components/moyo/modal.vue'
import { Bio, ChildBio } from '@/interfaces/bio'

@Component({
  name: 'Gallery',
  components: { modal, card },
})
export default class Gallery extends Vue {
  @Prop() items: Bio[] | ChildBio[]

  showModal(index: number) {
    const isMobile = window.innerWidth < 768
    this.$modal.show(
      modal,
      { bio: this.items[index], index },
      {
        width: isMobile ? '90%' : '500px',
        height: 'auto',
      }
    )
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import langIcon from '@/assets/icons/language.svg'

@Component({
  name: 'LanguageSelector',
  components: { langIcon }
})
export default class LanguageSelector extends Vue {
  @Prop() theme: 'light' | 'dark'
  setLocale(newLocale: 'hu' | 'en') {
    this.$i18n.locale = newLocale
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import { MenuItem } from '@/interfaces/menu-item'
import NewsletterForm from '@/components/moyo/newsletter-form.vue'

@Component({
  name: 'App',
  components: { Header, Footer, container, heading, NewsletterForm },
})
export default class App extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    window.scrollTo(0, 0)
  }

  mounted() {
    const width = Math.min(window.innerWidth, 400)
    if (window.localStorage.getItem('newsletter-modal-seen') !== 'true') {
      this.$modal.show(
        NewsletterForm,
        {},
        {
          width: `${width}px`,
          height: 'auto',
        }
      )
      window.localStorage.setItem('newsletter-modal-seen', 'true')
    }
  }

  getTranslation(path: string): string {
    return this.$i18n.t(path) as string
  }

  get menuItems(): MenuItem[] {
    return [
      {
        text: this.getTranslation('1.menu'),
        submenu: [
          { text: this.getTranslation('1.submenu1'), route: '/rolunk' },
          {
            text: this.getTranslation('1.submenu2'),
            route: '/eves-beszamolok',
          },
        ],
      },
      {
        text: this.getTranslation('2.menu'),
        submenu: [
          { text: this.getTranslation('2.submenu1'), route: '/alapelvek' },
          { text: this.getTranslation('2.submenu2'), route: '/hol-segitunk' },
          { text: this.getTranslation('2.submenu3'), route: '/projektjeink' },
        ],
      },
      {
        text: this.getTranslation('3.menu'),
        submenu: [
          { text: this.getTranslation('3.submenu1'), route: '/onkentesseg' },
          {
            text: this.getTranslation('3.submenu2'),
            route: '/projektjeink/akitogogon',
            ignoreInMenuHighlight: true,
          },
          {
            text: this.getTranslation('3.submenu3'),
            route: '/gyermektamogatas',
          },
          {
            text: this.getTranslation('3.submenu4'),
            route: '/graduate-a-girl',
          },
          {
            text: this.getTranslation('3.submenu5'),
            route: '/oktatasi-tamogatas',
          },
          { text: this.getTranslation('3.submenu6'), route: '/adomanyozas' },
          { text: this.getTranslation('3.submenu7'), route: '/esemenyek' },
        ],
      },
      {
        text: this.getTranslation('4.menu'),
        submenu: [
          { text: this.getTranslation('4.submenu1'), route: '/media' },
          { text: this.getTranslation('4.submenu2'), route: '/vlog' },
          { text: this.getTranslation('4.submenu3'), route: '/blog' },
        ],
      },
      { text: this.getTranslation('5.menu'), route: '/tamogatok' },
      { text: this.getTranslation('6.menu'), route: '/kapcsolat' },
    ]
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import { Slide } from 'vue-burger-menu'

import container from '@/components/moyo/container.vue'
import menuItem from '@/components/moyo/menu-item.vue'
import LanguageSelector from '@/components/moyo/language-selector.vue'
import menuButton from '@/assets/icons/menu-button.svg'
import { MenuItem } from '@/interfaces/menu-item'

@Component({
  name: 'Header',
  components: { container, menuItem, Slide, menuButton, LanguageSelector },
})
export default class Header extends Vue {
  @Prop() menuItems: MenuItem[]
  isMenuOpen = false

  setMenuOpen(val: boolean) {
    setTimeout(() => {
      this.isMenuOpen = val
    }, 0)
  }

  navigate(route: string) {
    this.$router.push({ name: route })
  }
}

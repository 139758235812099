
import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import YoutubeVideo from '@/components/moyo/youtube-video.vue';

@Component({
  name: 'Vlog',
  components: { container, heading, YoutubeVideo },
})
export default class Vlog extends Vue {
  get galleries() {
    return [
      {
        title: this.$i18n.t('2023-spring.title') as string,
        desc: this.$i18n.t('2023-spring.desc') as string,
        eps: [
          'https://www.youtube-nocookie.com/embed/eiIDIQg1BmU',
        ]
      },
      {
        title: this.$i18n.t('2023-january.title') as string,
        desc: this.$i18n.t('2023-january.desc') as string,
        eps: [
          'https://www.youtube-nocookie.com/embed/4xD-M7rfHUw',
          'https://www.youtube-nocookie.com/embed/UPjEr1Z5H4k',
        ]
      },
      {
        title: this.$i18n.t('2022-fall.title') as string,
        desc: this.$i18n.t('2022-fall.desc') as string,
        eps: [
          'https://www.youtube-nocookie.com/embed/j3hXOyV-JWE',
          'https://www.youtube-nocookie.com/embed/VMrP_GksjXc',
          'https://www.youtube-nocookie.com/embed/4EF3cgYQYkc',
          'https://www.youtube-nocookie.com/embed/8PVEAFqv8dc',
        ]
      },
      {
        title: this.$i18n.t('2022-spring.title') as string,
        desc: this.$i18n.t('2022-spring.desc') as string,
        eps: [
          'https://www.youtube-nocookie.com/embed/0eSG6uV1YWM',
          'https://www.youtube-nocookie.com/embed/rQzr0upAi3E',
          'https://www.youtube-nocookie.com/embed/SKjz8qx0RH0',
          'https://www.youtube-nocookie.com/embed/l_9sHjbe3YM',
        ]
      },
      {
        title: this.$i18n.t('2021-fall.title') as string,
        desc: this.$i18n.t('2021-fall.desc') as string,
        eps: [
          'https://www.youtube-nocookie.com/embed/-9CmuP4lB88',
          'https://www.youtube-nocookie.com/embed/2kppsBe8vss',
          'https://www.youtube-nocookie.com/embed/k-QZu2GQafU',
          'https://www.youtube-nocookie.com/embed/jeaF2EjEPmQ',
          'https://www.youtube-nocookie.com/embed/ucEJRKE8cHQ',
          'https://www.youtube-nocookie.com/embed/PrHcf30Sfic',
          'https://www.youtube-nocookie.com/embed/p2LVw95V8Pc',
          'https://www.youtube-nocookie.com/embed/ooAEdnu6CKY',
        ]
      },
    ]
  }
}


import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import fb from '@/assets/icons/facebook-logo.svg'
import insta from '@/assets/icons/instagram-logo.svg'
import NewsletterForm from './moyo/newsletter-form.vue'

@Component({
  name: 'Footer',
  components: { container, fb, insta },
})
export default class Footer extends Vue {
  showNewsletterModal() {
    const width = Math.min(window.innerWidth, 400)
    this.$modal.show(NewsletterForm, {}, {
      width: `${width}px`,
      height: 'auto',
    });
  }
}

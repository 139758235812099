
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Bio } from '@/interfaces/bio'
import close from '@/assets/icons/close.svg'

@Component({
  name: 'modal',
  components: { close },
})
export default class ModalComponent extends Vue {
  @Prop() bio: Bio
  @Prop() index: number
  @Prop() imageAutoHeight: boolean
  @Prop() lightCloseButton: boolean
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import chevron from '@/assets/icons/chevron.svg'
import SubmenuItem from './submenu-item.vue'
import { MenuItem as MenuItemI } from '@/interfaces/menu-item'

@Component({
  components: { SubmenuItem, chevron },
})
export default class MenuItem extends Vue {
  @Prop() submenu: MenuItemI[]
  @Prop() route: string
  @Prop() text: string
  selected = false
  open = false
  @Watch('$route', { immediate: true, deep: true })
  onUrlChange(newVal: any) {
    const submenuSelected = (this.submenu || []).some(
      (sm) => !sm.ignoreInMenuHighlight && newVal.path.includes(sm.route)
    )
    const menuSelected = newVal.path.includes(this.route)
    this.setSelected(submenuSelected || menuSelected)
  }

  mounted() {
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target as Node)) {
        this.setOpen(false)
      }
    })
  }

  setSelected(val: boolean) {
    this.selected = val
  }

  setOpen(val: boolean) {
    this.open = val
  }

  handleClick(event: Event) {
    if (this.submenu) {
      event.preventDefault()
      this.setOpen(!this.open)
    }
  }
}


import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import { ThemeSelectorCard } from '@/interfaces/theme-selector'

@Component({
  name: 'Projects',
  components: { container, heading },
})
export default class Projects extends Vue {
  cid: string = ''

  get selectedCard(): ThemeSelectorCard {
    return this.cards.find((c) => c.id === this.selectedCardId)!
  }

  get selectedCardId() {
    return this.cid
  }

  set selectedCardId(v) {
    this.cid = v
    window.scrollTo(0, 0)
  }

  get cards(): ThemeSelectorCard[] {
    return [
      {
        id: 'akitogogon',
        title: this.$i18n.t('akitogogon.title') as string,
        cover: 'akitogogon-1.jpg',
        imgStyle: { 'object-position': 'top' },
        shortDesc: this.$i18n.t('akitogogon.shortDesc') as string,
      },
      {
        id: 'noi-program',
        title: this.$i18n.t('womenprogram.title') as string,
        cover: 'noi-program-3.jpg',
        imgStyle: { 'object-position': '30%' },
        shortDesc: this.$i18n.t('womenprogram.shortDesc') as string,
      },
      {
        id: '84-nap',
        title: this.$i18n.t('84days.title') as string,
        cover: '84-nap-logo.png',
        shortDesc: this.$i18n.t('84days.shortDesc') as string,
      },
      {
        id: 'gyermektamogatas',
        title: this.$i18n.t('supportchildren.title') as string,
        cover: 'gyermektamogatas-projekt-2.jpg',
        imgStyle: { 'object-position': '60%' },
        shortDesc: this.$i18n.t('supportchildren.shortDesc') as string,
      },
      {
        id: 'shine',
        title: this.$i18n.t('shine.title') as string,
        cover: 'iskolafejlesztes-1.jpg',
        shortDesc: this.$i18n.t('shine.shortDesc') as string,
      },
      {
        id: 'graduate-a-girl',
        title: this.$i18n.t('graduateagirl.title') as string,
        cover: 'graduate-a-girl-projekt-1.jpg',
        shortDesc: this.$i18n.t('graduateagirl.shortDesc') as string,
      },
      {
        id: 'mtwapa',
        title: this.$i18n.t('mtwapa.title') as string,
        cover: 'mtwapa.jpg',
        shortDesc: this.$i18n.t('mtwapa.shortDesc') as string,
      },
      {
        id: 'eu-program',
        title: this.$i18n.t('euprogram.title') as string,
        cover: 'eu-2.jpg',
        imgStyle: { 'object-position': 'top' },
        shortDesc: this.$i18n.t('euprogram.shortDesc') as string,
      },
    ]
  }

  created() {
    if (this.$route.params.projectId) {
      this.selectedCardId = this.$route.params.projectId
    }

    this.$watch(
      () => this.$route.params.projectId,
      (projectId) => {
        this.selectedCardId = projectId
      }
    )
  }
}

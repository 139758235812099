
import { Component, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'
import heading from '@/components/moyo/heading.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

@Component({
  name: 'Home',
  components: { container, heading, Swiper, SwiperSlide },
})
export default class Home extends Vue {
  swiperOptions = {
    autoplay: {
      delay: 5000,
    },
    loop: true,
    slidesPerGroup: 1,
    slidesPerView: 1,
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator'
import container from '@/components/moyo/container.vue'

@Component({
  name: 'heading',
  components: { container }
})
export default class YoutubeVideo extends Vue {
  @Prop() url: string
}
